<template>
  <div class="wrapper">
    <v-sideBar></v-sideBar>
    <div class="main-wrapper">
      <transition name="move" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import vSideBar from 'components/sidebar/sidebar';
export default {
	components: {
		'v-sideBar': vSideBar,
	},
	data() {
		return {};
	},
};
</script>

<style lang="css" scoped>
.main-wrapper {
  margin-left: 220px;
  background-color: #e9ebec;
}
</style>
