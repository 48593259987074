<template>
  <div class="sidebar">
    <div class="siderbar-header">
      <div class="logo tac">
        <img src="@/assets/img/logo.png" alt />
      </div>
      <div class="user tac">
        <a class="avatarbg" href="/#/base/user">
          <img :src="userData.avatar" class="avatar" alt v-if="userData.avatar" />
          <img src="@/assets/img/bg_avatar.png" alt class="avatar" v-else />
        </a>
        <div class="identity">
          <div class="triangle"></div>
          {{userData.position}}
        </div>
        <div class="name">{{userData.name}}</div>
        <div @click="exit" class="exit">退出</div>
      </div>
    </div>
    <ul class="sidebar-nav">
      <li>
        <router-link class="home" to="/base/home">
          首页
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('会员管理') > -1">
        <router-link class="member" to="/base/member">
          会员档案
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li>
        <router-link class="member" to="/base/family">
          家庭档案
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('服务管理') > -1">
        <router-link class="plan" to="/base/advisory-manage">
          咨询管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('服务管理') > -1">
        <router-link class="plan" to="/base/promotion-manage">
          促进管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('数据统计') > -1">
        <router-link class="report" to="/base/report">
          数据报告
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('服务管理') > -1">
        <router-link class="plan" to="/base/blood-sugar-manage">
          持续体糖管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('服务管理') > -1">
        <router-link class="plan" to="/base/life">
          生命线管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('服务管理') > -1">
        <router-link class="plan" to="/base/card">
          卡系管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('方案管理') > -1">
        <router-link class="plan" to="/base/plan">
          方案管理2019
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('促进服务管理') > -1">
        <router-link class="boost" to="/base/boost">
          促进服务管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('医生') > -1">
        <router-link class="doctor" to="/base/doctor">
          医生
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('质检中心') > -1">
        <router-link class="quality" to="/base/quality">
          质检中心
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('问题中心') > -1">
        <router-link class="problem" to="/base/problem">
          问题中心
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('预警管理') > -1">
        <router-link class="warning" to="/base/warning">
          预警管理
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('数据统计') > -1">
        <router-link class="statistics" to="/base/statistics">
          数据统计
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('数据统计') > -1">
        <router-link class="report" to="/base/punch">
          打卡进店
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('数据统计') > -1">
        <router-link class="report" to="/base/promotion">
          促进记录
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('图表分析') > -1">
        <router-link class="chart-icon" to="/base/chart">
          图表分析
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('图表分析') > -1">
        <router-link class="copyright-icon" to="/base/copyright">
          知识产权
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
      <li v-if="this.power.indexOf('评估问卷') > -1">
        <router-link class="chart-icon" to="/base/questionnaire">
          评估问卷
          <Icon type="ios-arrow-forward"></Icon>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script type="text/ecmascript-6">
import { mapActions, mapGetters } from 'vuex';
export default {
	data() {
		return {
			power: '',
		};
	},
	computed: {
		...mapGetters({ userData: 'getUserData', userInfo: 'userInfo' }),
	},
	methods: {
		...mapActions(['getUserData']),
		exit() {
			localStorage.removeItem('autoLogin');
			localStorage.removeItem('userInfo');
			localStorage.removeItem('loginInfo');
			localStorage.removeItem('userPower');
			this.$router.push('/login');
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		let params = { id: JSON.stringify(this.userInfo.id) };
		this.getUserData(params);
	},
};
</script>
<style scoped>
.sidebar {
  display: block;
  position: fixed;
  width: 220px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #32323a;
  z-index: 666;
  min-height: 0;
  overflow: auto;
}

.siderbar-header {
  height: 224px;
  background: url(@/assets/img/lbg.png) repeat-x;
}

.siderbar-header .logo {
  padding: 20px 0 16px;
}

.user {
  position: relative;
}

.user .avatarbg {
  display: block;
  margin: 0 auto;
  width: 94px;
  height: 94px;
  border-radius: 100%;
  background-color: rgba(245, 156, 26, 0.1);
}

.user .avatarbg .avatar {
  margin-top: 5px;
  width: 84px;
  height: 84px;
  border-radius: 100%;
}

.user .identity {
  position: absolute;
  font-size: 12px;
  color: #f1f1f1;
  padding: 2px 5px;
  background-color: #51c78a;
  border-radius: 2px;
  top: 65px;
  left: 140px;
}

.user .identity .triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-width: 4px 6px;
  border-style: solid;
  border-color: transparent #51c78a transparent transparent;
  position: absolute;
  top: 7px;
  left: -12px;
}

.user .name {
  color: #fff;
  line-height: 28px;
}
.user .exit {
  color: #999;
  cursor: pointer;
  font-size: 12px;
}

.sidebar-nav li {
  position: relative;
  height: 43px;
  line-height: 43px;
  border-top: 1px solid #383840;
  border-bottom: 1px solid #28282e;
}

.sidebar-nav li a {
  display: block;
  padding-left: 50px;
  color: #9caab3;
}

.sidebar-nav li a.active {
  color: #fff;
  background-color: #fdad00;
}

.sidebar-nav li a i {
  position: absolute;
  top: 15px;
  right: 17px;
  font-size: 12px;
  color: #fff;
}

.sidebar-nav li .home {
  background: url(@/assets/img/icon_home.png) no-repeat 20px center;
}

.sidebar-nav li .home.active {
  background-image: url(@/assets/img/icon_home_hover.png);
}

.sidebar-nav li .member {
  background: url(@/assets/img/icon_member.png) no-repeat 20px center;
}

.sidebar-nav li .member.active {
  background-image: url(@/assets/img/icon_member_hover.png);
}

.sidebar-nav li .risk {
  background: url(@/assets/img/icon_risk.png) no-repeat 20px center;
}

.sidebar-nav li .risk.active {
  background-image: url(@/assets/img/icon_risk_hover.png);
}

.sidebar-nav li .plan {
  background: url(@/assets/img/icon_plan.png) no-repeat 20px center;
}

.sidebar-nav li .plan.active {
  background-image: url(@/assets/img/icon_plan_hover.png);
}

.sidebar-nav li .boost {
  background: url(@/assets/img/icon_boost.png) no-repeat 20px center;
}

.sidebar-nav li .boost.active {
  background-image: url(@/assets/img/icon_boost_hover.png);
}

.sidebar-nav li .doctor {
  background: url(@/assets/img/icon_doctor.png) no-repeat 20px center;
}

.sidebar-nav li .doctor.active {
  background-image: url(@/assets/img/icon_doctor_hover.png);
}

.sidebar-nav li .quality {
  background: url(@/assets/img/icon_quality.png) no-repeat 20px center;
}

.sidebar-nav li .quality.active {
  background-image: url(@/assets/img/icon_quality_hover.png);
}

.sidebar-nav li .problem {
  background: url(@/assets/img/icon_problem.png) no-repeat 20px center;
}

.sidebar-nav li .problem.active {
  background-image: url(@/assets/img/icon_problem_hover.png);
}

.sidebar-nav li .warning {
  background: url(@/assets/img/icon_warning.png) no-repeat 20px center;
}

.sidebar-nav li .warning.active {
  background-image: url(@/assets/img/icon_warning_hover.png);
}

.sidebar-nav li .statistics {
  background: url(@/assets/img/icon_statistics.png) no-repeat 20px center;
}

.sidebar-nav li .statistics.active {
  background-image: url(@/assets/img/icon_statistics_hover.png);
}

.sidebar-nav li .report {
  background: url(@/assets/img/icon_chart.png) no-repeat 20px center;
}

.sidebar-nav li .report.active {
  background-image: url(@/assets/img/icon_chart_hover.png);
}

.sidebar-nav li .chart-icon {
  background: url(@/assets/img/icon_chart.png) no-repeat 20px center;
}

.sidebar-nav li .chart-icon.active {
  background-image: url(@/assets/img/icon_chart_hover.png);
}

.sidebar-nav li .copyright-icon {
  background: url(@/assets/img/copyright.png) no-repeat 20px 8px / 16px;
}

.sidebar-nav li .copyright-icon.active {
  background-image: url(@/assets/img/copyright.png);
}
</style>
